import { CasesPage, CasesPage_Cases } from '@/content/types'
import Link from 'next/link'
import CaseTag from './CaseTag'
import { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'
import { attributes } from '../content/projects.md'
import { useLocale } from '@/hooks/useLocale'
import Image from 'next-export-optimize-images/image'
import ZoomAnimation from '@/molecules/ZoomAnimation'
import ArrowSlideAnimation from './ArrowSlideAnimation'

type CaseTileProps = {
  caseInfo: CasesPage_Cases
}

const ConditionalLink = ({
  className,
  ...props
}: ComponentProps<typeof Link>) => {
  if (props.href == '') {
    return <div className={className}>{props.children}</div>
  }

  return <Link className={twMerge(className, 'cursor-pointer')} {...props} />
}

const CaseTile = ({ caseInfo }: CaseTileProps) => {
  const { translations } = useLocale<CasesPage>(attributes)
  const projectsLink = caseInfo.href
    ? `/projects/${encodeURIComponent(caseInfo.href)}`
    : ''

  return (
    <ConditionalLink
      href={projectsLink}
      className="relative overflow-hidden group w-full text-white"
    >
      <ZoomAnimation wrapperClassName="absolute inset-0 z-0">
        <Image
          alt={caseInfo.blurb}
          /* This width/height is close to the size that it will be rendered at in a browser */
          width={440}
          height={590}
          className="h-full w-full object-cover"
          src={caseInfo.background}
        />
      </ZoomAnimation>
      <div className="flex flex-col h-full justify-between gap-20 relative z-20">
        {/* Tags */}
        <div className="w-full pl-6 flex flex-wrap justify-end">
          {(caseInfo.labels || []).map((label, i) => (
            <CaseTag key={i} label={label.label} />
          ))}
        </div>
        {/* Title and Description */}
        <div className="w-full">
          {/* Work In Progress title if the case has no link */}
          {!caseInfo.href && (
            <div className="lg:invisible group-hover:visible text-white text-center text-xl lg:my-0 lg:mb-20 [text-shadow:_0_0_16px_rgba(0,0,0,.8)]">
              {translations.wip_text}
            </div>
          )}
          {/* Title row (shift for animation) */}
          <div className="bg-gradient-to-b from-black/0 via-black/40 to-black/50 p-10">
            <div className="text-xl mb-4">{caseInfo.blurb}</div>
            {/* a container that has an arrow on the right and the name of the client on the left */}
            <div className="text-xs flex justify-between">
              <div>{caseInfo.organization}</div>
              <ArrowSlideAnimation />
            </div>
          </div>
        </div>
      </div>
    </ConditionalLink>
  )
}

export default CaseTile

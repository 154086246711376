import SwiperCarousel, {
  partialTiledSettings,
} from '@/organisms/SwiperCarousel'
import CaseTile from '@/atoms/CaseTile'
import { CasesPages } from '@/content/types'
import { ComponentProps } from 'react'

export const loadCaseFromSlug = (
  slug: string,
  locale: string,
): ComponentProps<typeof CaseTile>['caseInfo'] => {
  const {
    attributes,
  }: {
    attributes: Record<string, CasesPages>
  } = require(`@/content/cases/${slug}.md`)

  const thumbnail = attributes[locale].thumbnail

  return {
    background: thumbnail.image,
    organization: thumbnail.organization,
    blurb: thumbnail.blurb,
    href: slug,
    labels: [],
  }
}

const Cases = ({ cases, locale }: { cases: any[]; locale: string }) => {
  return (
    <div className="lg:px-container-padding lg:max-w-outer-container lg:mx-auto pb-8 lg:pb-0">
      <SwiperCarousel settings={partialTiledSettings}>
        {cases.map((c) => {
          const info = loadCaseFromSlug(c, locale)

          return (
            <div className="aspect-[22/29]" key={c}>
              <CaseTile caseInfo={info} />
            </div>
          )
        })}
      </SwiperCarousel>
    </div>
  )
}

export default Cases
